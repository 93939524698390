<template>
  <v-card width="100%">
    <v-card-title>{{ $t('vendor.onboarding.submitVendorTitle') }}</v-card-title>
    <v-card-text class="text-pre-wrap text-body-1">{{
      $t('vendor.onboarding.submitVendorMessage')
    }}</v-card-text>
    <v-card-text
      class="text-subtitle-2 d-flex align-center font-weight-semibold"
      style="column-gap: 10px"
    >
      <v-icon small>{{ mdiInformation }}</v-icon>
      {{ $t('vendor.onboarding.submitVendorInfoCaption') }}
    </v-card-text>
    <v-card-actions>
      <v-btn
        @click="$emit('submit')"
        color="primary"
        large
        class="px-4 font-weight-semibold"
        >{{ $t('vendor.onboarding.submitVendorTitle') }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'OnBoardingSubmit'
};
</script>

<style></style>
